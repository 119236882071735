.top-bar {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    padding: 1rem 2rem;

    a {
        text-decoration: none;
    }

    .text {
        padding: 0.6rem 0.8rem;
        border-radius: 4px;
    }
}

.top-bar.light {
    a {
        color: #000000;
    }

    .text {
        background-color: #000000;
        color: #ffffff;
    }
}

.top-bar.dark {
    a {
        color: #ffffff;
    }
    
    .text {
        background-color: #ffffff;
        color: #000000;
    }
}

@media (max-width: 480px) {
    
    .top-bar {
        padding: 1rem 0rem 1rem 2rem;
    }
}