* {
    margin: 0;
    transition: background-color 0.3s linear, color 0.1s linear;
}

body {  
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 5rem;
    font-weight: 600;
}

h2 {
    font-size: 4rem;
    font-weight: 600;
}

h3 {
    font-size: 2.5rem;
    font-weight: 600;
}

h4 {
    font-size: 2rem;
    font-weight: 600;
}

h5 {
    font-size: 1.5rem;
    font-weight: 600;
}

p {
    line-height: 1.5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@media (max-width: 480px)  {
    h1 {
        font-size: 3em;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2rem;
    }

    h4 {
        font-size: 2rem;
    }

    h5 {
        font-size: 1.25rem;
    }
}
