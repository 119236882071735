.greeting {
    
    h1 {
        margin-bottom: 2rem;
    }
    
    span {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px)  {
    .greeting {
        text-align: center;

        span {
            font-size: 1rem;
        }
    }
}