.app {
    display: flex;
    flex-direction: column;
    row-gap: 10rem;

    .top-bar-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        // sticky properties
        top: 0;
        position: sticky;
        width: 100%;
        animation: 0.75s slide-left ease;

        .theme-toggle {
            cursor: pointer;
        }
    }

    .greeting-section {
        margin-left: 12rem;
        animation: slide-down-more 0.75s ease;
    }

    .experience-section {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        margin-left: 10rem;

        animation: slide-down 0.75s ease;
    }

    .projects-section {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        animation: slide-down 0.75s ease;
        
        h3 {
            margin-left: 10rem;
        }
    }
}

.app.light {
    background-color: #ffffff;
    color: #000000;

    .top-bar-section {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(40px);
    }
}

.app.dark {
    background-color: #000000;
    color: #ffffff;

    .top-bar-section {
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(40px);
    }
}

// @supports()

@media (max-width: 1200px)  {
    .app {
        .greeting-section {
            margin-left: 6rem;
        }

        .experience-section {
            margin-left: 4rem;
        }

        .projects-section {

            h3 {
                margin-left: 4rem;
            }
        }
    }
}

@media (max-width: 480px)  {
    .app {
        row-gap: 6rem;

        .top-bar-section {
            display: flex;
            justify-content: center;
            animation: slide-down 1s ease;
        }

        .greeting-section {
            margin: 0 1rem;
        }

        .experience-section {
            align-items: center;
            row-gap: 3rem;
            margin: 1rem;
        }

        .projects-section {
            align-items: center;
            row-gap: 3rem;

            h3 {
                margin-left: 0;
            }
        }
    }
}


@keyframes slide-left {
    from {
        opacity: 0;
        transform: translateX(2%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-2%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes slide-down-more {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}